export const technicalSkillsConfig = [
  "HTML",
  "CSS",
  "Bootstrap",
  "Tailwind CSS",
  "JavaScript",
  "React",
  "TypeScript",
  "NextJs",
  "Redux",
  "Firebase",
  "Node.js",
  "Zustand",
];
