export const experienceConfig = [
  {
    name: "Arex Digitals Web Developer Agency",
    title: "Frontend Engineer",
    description: [
      "Collaborated with designers and backend developers to build responsive websites using HTML, CSS, and JavaScript, ensuring a consistent user experience across devices.",
      "Optimized website performance by minimizing file sizes, optimizing images, and improving code efficiency for faster load times and improved user satisfaction.",
      "Conducted code reviews and participated in team discussions to maintain code quality standards and foster a culture of continuous learning and growth.",
    ],
    date: "May 2023 - Current",
  },
  {
    name: "Frontend Developer Internship",
    title: "Frontend Simplified",
    description: [
      "Co-founded a Frontend Development Agency with a professional associate, our central mission is to bridge the gap between individuals and businesses through the design and development of highly accessible websites.",
      "Our talented team of designers applies UX and UI principles strategically to enhance product revenues, augment conversion rates, and boost brand visibility while fostering customer loyalty.",
      "Our globally dispersed team fosters a culture of diversity that naturally engenders unique and innovative solutions. Each project we undertake is imbued with a distinct touch of originality, thus ensuring our clients attain a competitive edge in their respective markets.",
    ],
    date: "Dec 2021 - Present",
  },
  {
    name: "Frontend Developer Internship",
    title: "Frontend Simplified",
    description: [
      "Executed a comprehensive overhaul of a static HTML, CSS, JavaScript, and React-based single-page application, integrating interactive features such as animations, transitions, and carousels to enhance the user interface.",
      "Leveraged Axios for processing API requests, enabling dynamic data representation fetched from a cloud server, further improved by the introduction of skeleton loading states, pagination, and dynamic routing for seamless user experience.",
      "Employed Git version control alongside the GitHub interface to promote efficient teamwork within a virtual collaborative environment, thereby contributing to the overall team's productivity and project success.",
    ],
    date: "Jul 2021 - Dec 2021",
  },
];
